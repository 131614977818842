import React, { Component,Suspense,lazy } from 'react';
import { Switch, Route,Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';


const Login = lazy(() => import('../view/Login'));
const Dashboard = lazy(() => import('../view/Dashboard'));
const cart = lazy(() => import('../view/Dashboard2'));


const Validation = lazy(() => import('../view/ValidationDelivery'));
const ValidationCabang = lazy(() => import('../view/ValidationDeliveryCabang'));
const ValidationDetail = lazy(() => import('../view/ValidationDeliveryDetail'));
const ValidationView = lazy(() => import('../view/ValidationDeliveryView'));
const ValidationEdit = lazy(() => import('../view/ValidationDeliveryEdit'));

const TransaksiAR = lazy(() => import('../view/TransaksiAR'));
const TransaksiARDetail = lazy(() => import('../view/TransaksiARDetail'));


const BankValidasiPenerima = lazy(() => import('../view/BankValidasiPenerima'));
const BankValidasiAR = lazy(() => import('../view/BankValidasiAR'));
const LaporanTF = lazy(() => import('../view/LaporanTransfer'));
const LaporanTFHplush = lazy(() => import('../view/LaporanTransferplush'));
const Invoice = lazy(() => import('../view/Invoice'));
const Report = lazy(() => import('../view/Report'));
const InputPaket = lazy(() => import('../view/InputPaket'));
const BuatPengajuan = lazy(() => import('../view/BuatPengajuanCashback'));
const BuatRekap = lazy(() => import('../view/RekapJemput'));
const ProsesPencairan = lazy(() => import('../view/ProsesPencairanCashback'));
const Kendala = lazy(() => import('../view/Kendala'));
const MapingKurir = lazy(() => import('../view/MapingKurir'));
const ReturnKurir = lazy(() => import('../view/ReturnKurir'));
const LacakPaket = lazy(() => import('../view/LacakPaket'));

const MasterKurir = lazy(() => import('../view/MasterKurir'));
const MasterOlshop = lazy(() => import('../view/MasterOlshop'));
const Verifikasi = lazy(() => import('../view/OlshopVerifikasi'));
const MasterOngkir = lazy(() => import('../view/MasterOngkir'));
const paket_masuk = lazy(() => import('../view/Alldatapaket'));
const banner_informasi = lazy(() => import('../view/bannerinformasi'));


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/login" component={ Login } />
          <Route path="/dashboard" component={ Dashboard } />  
          <Route path="/chart" component={ cart } />  


          {/* VALIDASI DELIVERY */}
          <Route exact path="/validation/delivery/add/:id" render={(props) => (
              <ValidationDetail id={props.match.params.id}/>
          )} />
         
          <Route exact path="/validation/delivery/cabang/:id" render={(props) => (
              <ValidationDetail id={props.match.params.id}/>
          )} />
          
          <Route exact path="/validation/delivery/view/:id" render={(props) => (
              <ValidationView id={props.match.params.id}/>
          )} />

          <Route exact path="/validation/delivery/edit/:id" render={(props) => (
              <ValidationEdit id={props.match.params.id}/>
          )} />

          <Route path="/validation/delivery" component={ Validation } />
          <Route path="/validation/cabang" component={ ValidationCabang } />


           {/*BANK VALIDASI  PENERIMA*/}
    
          <Route path="/bank-validasi/penerima" component={ BankValidasiPenerima } />

          {/*BANK VALIDASI  AR TERIMA*/}
    
          <Route path="/bank-validasi/ar-terima" component={ BankValidasiAR } />

          <Route path="/transaksi/laporan-tf" component={ LaporanTF } />
          <Route path="/transaksi/laporantf-hp" component={ LaporanTFHplush } />
          <Route path="/transaksi/invoice" component={ Invoice } />


          
           {/* TRANSAKSI AR */}
          <Route exact path="/transaksi/ar-terima/add/:id" render={(props) => (
              <TransaksiARDetail id={props.match.params.id}/>
          )} />

          <Route path="/transaksi/ar-terima" component={ TransaksiAR } />

          <Route path="/report" component={ Report } />
          <Route path="/input/paket" component={ InputPaket } />
          <Route path="/input/Kendala" component={ Kendala } />
          <Route path="/input/maping" component={ MapingKurir } />
          <Route path="/input/return" component={ ReturnKurir } />
          <Route path="/paket/masuk" component={ paket_masuk } />

            
          {/*CASHBACK */}
          <Route path="/cashback/buat-pengajuan" component={ BuatPengajuan } />
          <Route path="/cashback/proses-pencairan" component={ ProsesPencairan } />
         
          {/*REKAP */}
          <Route path="/rekap" component={ BuatRekap } />       

          {/*Master */}
          <Route path="/master-user/kurir" component={ MasterKurir } />
          <Route path="/master-user/olshop/verifikasi" component={ Verifikasi } />
          <Route path="/master-user/olshop" component={ MasterOlshop } />
        
          <Route path="/tracking" component={ LacakPaket } />

          <Route path="/master-app/branch" component={ MasterOngkir } />
          <Route path="/master-app/banner-informasi" component={ banner_informasi } />
          <Route path="/master-app/ongkir" component={ MasterOngkir } />
        
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;