import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { Trans } from "react-i18next";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StatusLogin: true,
    };
  }

  componentDidMount() {
    this.ValidasiLogin();
  }

  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  ValidasiLogin() {
    let token = localStorage.getItem("wt");

    if (token === null) {
      this.setState({ StatusLogin: false });
    }
  }

  render() {
    const Logout = (e) => {
      e.preventDefault();
      localStorage.removeItem("wt");
      this.setState({ StatusLogin: false });
    };

    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/">
            <img src={process.env.REACT_APP_LOGO_COMPANY} alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img src={process.env.REACT_APP_LOGO_COMPANY} alt="logo" />
          </Link>

          {this.state.StatusLogin === false ? <Redirect to="/login" /> : ""}
        </div>

        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu"></span>
          </button>
          {/*}
          <div className="search-field d-flex align-items-center h-100">
            <div className="">
              <input
                type="text"
                className="text-white  form-control bg-transparent border-0"
                placeholder="Cari resi atau nama paket"
              />
            </div>
          </div>
    {*/}
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link">
                  <div className="nav-profile-text">
                    <p className="mb-1 text-white">
                      <Trans>{localStorage.getItem("nama")}</Trans>
                    </p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item href="!#" onClick={Logout}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Logout</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
